<template>
  <v-main>
    <div class="py-16 text-center" v-if="isLoading">
      <v-progress-circular
        :size="50"
        color="secondary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-container class="py-8 pt-4" v-if="!isLoading && categories">
      <v-breadcrumbs :items="items" divider="/" class="px-2"></v-breadcrumbs>
      <v-row>
        <v-col cols="12" class="pa-5">
          <h3
            to="/categories"
            class="text-h4 primary--text d-inline-block mb-5"
          >
            {{ category.name }}
          </h3>
          <ul>
            <li v-for="item in articlesOrdered" :key="item.slugs[0]">
              <router-link
                :to="'/articles/' + item.uid"
                class="app-link text-decoration-none d-inline-block mb-2"
              >
                {{ $prismic.asText(item.data.title) }}
              </router-link>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template><script>
export default {
  props: {
    categories: {
      type: Array,
    },
  },
  data: () => ({
    isLoading: true,
    articles: [],
  }),
  watch: {
    "$i18n.locale"() {
      this.loadContent();
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale === "es" ? "es-es" : "en-gb";
    },
    category() {
      return this.categories.find((c) => c.slug == this.$route.params.uid);
    },
    items() {
      return [
        {
          text: this.$i18n.t("support"),
          disabled: false,
          to: "/",
        },
        {
          text: this.category ? this.category.name : "",
          disabled: true,
        },
      ];
    },
    articlesOrdered() {
      return this.articles.results.sortBy('position')
    }
  },
  methods: {
    setTitle() {
      this.$title = this.category.name;
    },
    async loadContent() {
      this.isLoading = true;
      await this.getArticles();
      this.isLoading = false;
      this.setTitle();
    },
    async getArticles() {
      const document = await this.$prismic.client.query(
        this.$prismic.Predicates.any("document.tags", [this.$route.params.uid]),
        { lang: this.locale }
      );
      this.articles = document;
    },
  },
  created() {
    this.loadContent();
  },
  beforeRouteUpdate(to, from, next) {
    this.loadContent();
    next();
  },
};
</script>